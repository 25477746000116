// import { BrowserRouter as Router, Route } from 'react-router-dom';
import Header from './components/Header';
import Services from './components/Services';
import About from './components/About';
import Gallery from './components/Gallery';
import Testimonials from './components/Testimonials';
import Footer from './components/Footer';


function App() {
  return (
    <>
      <Header />
      <Services />
      <About />
      <Gallery />
      <Testimonials />
      <Footer />
    </>
  );
}

export default App;

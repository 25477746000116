import React from 'react'
import Zoom from 'react-medium-image-zoom'
import 'react-medium-image-zoom/dist/styles.css'

function Gallery() {
  return (
    <div className="cases flex-grid section-padding">
      <header className="section-header" id="Gallery">
        <h2>Project Gallery</h2>
      </header>
      <div className="row">
        <div className="column">
          <Zoom><img src={require('../images/examples/image_001.jpeg')} alt="" /></Zoom>
          <Zoom><img src={require('../images/examples/image_002.jpeg')} alt="" /></Zoom>
        </div>
        <div className="column">
          <Zoom><img src={require('../images/examples/image_003.jpeg')} alt="" /></Zoom>
          <Zoom><img src={require('../images/examples/example_009.jpg')} alt="" /></Zoom>
        </div>
        <div className="column">
          <Zoom><img src={require('../images/examples/image_005.jpeg')} alt="" /></Zoom>
          <Zoom><img src={require('../images/examples/image_006.jpeg')} alt="" /></Zoom>
        </div>
        <div className="column">
          <Zoom><img src={require('../images/examples/image_007.jpeg')} alt="" /></Zoom>
          <Zoom><img src={require('../images/examples/image_008.jpeg')} alt="" /></Zoom>
        </div>
      </div>
    </div>
  )
}

export default Gallery
import React from 'react'

function About() {
  return (
    <div className="solutions flex-columns">
      <div className="row">
        <div className="column">
          <div className="column-1">
            <img src={require('../images/repair.jpg')} alt="" id="About" />
          </div>
        </div>
        <div className="column">
          <div className="column-2 bg-primary">
            <h2>About Us</h2>
            <p>Scenic Exteriors is a family-owned business solely focused on providing high quality craftsmanship with reliable service from initial contact to completion. Whether you are in need of a roof repair, replacement, or maintenance, our team of experienced professionals is here to help. We use only the best materials and the latest techniques to ensure that your roof is strong, durable, and long-lasting.</p>
            <p>When you work with us, you can expect personalized attention, exceptional service, and outstanding results. We are committed to providing the highest level of satisfaction, and we won't stop until you are completely happy with the work we've done.</p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default About


import React from 'react'

function Footer() {
  return (
    <div>
      <footer className="footer bg-dark">
        <div className="flex-items footer-end">
          <div className='footerLogo'>
            <img src={require('../images/Logo.png')} alt="" />
          </div>
          <div className='footer-end contact'>
            <div>
              <div>
                <h3>Contact Us</h3>
                <p>Office: (385) 272 - 8358</p>
                <p>Email: info@scenicexteriors.com</p>
              </div>
            </div>
            <div >
              <div>
                <h3>Hours</h3>
                <p>Mon-Fri: 9AM-5PM</p>
              </div>
            </div>
          </div>
        </div>
        <div className="copy">
          <h4>Copyright &copy; 2023 - Scenic Exteriors</h4>
        </div>
      </footer>
    </div>
  )
}

export default Footer


import React from 'react'

function Testimonials() {
  return (
    <div>
      <header className="testimonial-header" id="Testimonials">
        <h2>What Our Clients Are Saying About Us</h2>
      </header>
      <div className="flex-items testimonials">
        <div>
          <img src={require('../images/user2.png')} alt="" />
          <div>
            <h3>Katie D.</h3>
            <p>
              "Scott and his team were very knowledgeable and able to get the job completed quickly."
            </p>
          </div>
        </div>
        <div>
          <img src={require('../images/user2.png')} alt="" />
          <div>
            <h3>Brianne L.</h3>
            <p>
              "Excellent communication, great follow through, friendly staff. Highly recommend."
            </p>
          </div>
        </div>
        <div>
          <img src={require('../images/user2.png')} alt="" />
          <div>
            <h3>David D.</h3>
            <p>
              "They worked fast and were extremely efficient. Our new roof looks better than we could have imagined."
            </p>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Testimonials

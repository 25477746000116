import React from 'react'

function Services() {
  return (
    <div className='icons bg-light'>
      <header className="service-header" id="Services">
        <h2>Services</h2>
      </header>
      <div class="flex-items">
        <div>
          <img src={require('../icons/house.png')} alt="" />
          <div>
            <h3>Residential Roofing</h3>
          </div>
        </div>
        <div>
          <img src={require('../icons/building.png')} alt="" />
          <div>
            <h3>Commercial Roofing</h3>
          </div>
        </div>
        <div>
          <img src={require('../icons/broken-house.png')} alt="" />
          <div>
            <h3>Repairs</h3>
          </div>
        </div>
        <div>
          <img src={require('../icons/3d.png')} alt="" />
          <div>
            <h3>Soffit and Fascia</h3>
          </div>
        </div>
        <div>
          <img src={require('../icons/roof.png')} alt="" />
          <div>
            <h3>Gutters</h3>
          </div>
        </div>
        <div>
          <img src={require('../icons/plug.png')} alt="" />
          <div>
            <h3>Heat Cables</h3>
          </div>
        </div>
        <div>
          <img src={require('../icons/shovel.png')} alt="" />
          <div>
            <h3>Snow Removal</h3>
          </div>
        </div>
      </div>
    </div>
  )
}

export default Services

import React from 'react'
import { Link } from 'react-scroll'

function Header() {
  return (
    <>
      <header className='hero'>
        <div className='navbar'>
          <div className="logo">
            <Link activeClass="active" to="Top" spy={true} smooth={true}><img src={require('../images/Logo.png')} alt="" /></Link>
          </div>
          <nav>
            <ul>
              <li><Link activeClass="active" to="Services" spy={true} smooth={true}>Services</Link></li>
              <li><Link activeClass="active" to="About" spy={true} smooth={true}>About</Link></li>
              <li><Link activeClass="active" to="Gallery" spy={true} smooth={true}>Gallery</Link></li>
              <li><Link activeClass="active" to="Testimonials" spy={true} smooth={true}>Testimonials</Link></li>
            </ul>
          </nav>
        </div>
        <div className='content' id="Top">
          <h1>Quality Craftmanship With Service You Can Trust</h1>
          <h2>Get a Free Quote Today (385) 272 - 8358</h2>
        </div>
      </header>
    </>
  )
}

export default Header